export default class Map {
  constructor(element) {
    this.element = element;
    this.pointers = this.element.querySelectorAll('.pointer');

    this.init();
  }

  init() {
    // Add click event listeners to all pointers
    this.pointers.forEach((pointer) => {
      pointer.addEventListener('click', (event) => {
        event.stopPropagation(); // Stop propagation to prevent hiding the information
        this.showInfo(pointer);
      });
    });

    // Close info when clicking outside
    document.addEventListener('click', () => {
      this.closeAllInfo();
    });
  }
  showInfo(clickedPointer) {
    // Toggle active class to show/hide content-information for the clicked pointer
    const info = clickedPointer.querySelector('.content-information');
    if (info) {
      info.classList.toggle('active');
    }

    // Toggle active class for .content-information-desktop-hide
    const pointerIndex = Array.from(
      clickedPointer.parentNode.querySelectorAll('.pointer')
    ).indexOf(clickedPointer);
    const hideInfo = clickedPointer.parentNode.querySelectorAll(
      '.content-information-desktop-hide'
    )[pointerIndex];
    if (hideInfo) {
      hideInfo.classList.toggle('active');
    }

    // Close all other info elements
    this.pointers.forEach((pointer) => {
      if (pointer !== clickedPointer) {
        const otherInfo = pointer.querySelector('.content-information');
        if (otherInfo) {
          otherInfo.classList.remove('active');
        }
        const otherPointerIndex = Array.from(
          pointer.parentNode.querySelectorAll('.pointer')
        ).indexOf(pointer);
        const otherHideInfo = pointer.parentNode.querySelectorAll(
          '.content-information-desktop-hide'
        )[otherPointerIndex];
        if (otherHideInfo) {
          otherHideInfo.classList.remove('active');
        }
      }
    });
  }

  closeAllInfo() {
    // Close all info elements
    this.pointers.forEach((pointer) => {
      const info = pointer.querySelector('.content-information');
      if (info) {
        info.classList.remove('active');
      }
    });

    const hideInfo = document.querySelector(
      '.content-information-desktop-hide'
    );
    if (hideInfo) {
      hideInfo.classList.remove('active');
    }
  }
}

// Initialize the Map class
document.addEventListener('DOMContentLoaded', () => {
  const mapContainer = document.querySelector('[data-component="map"]');
  if (mapContainer) {
    new Map(mapContainer);
  }
});
