/** Composante Form de Timtools */

/**
 * Description de ce que fait cette méthode
 * @param {HTMLElement} element - Tout les champs de texte du form

 */

export default class Form {
  constructor(element) {
    this.element = element;
    this.formElements = this.element.elements;

    this.init();
  }

  init() {
    this.element.setAttribute('novalidate', '');

    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];

      // Si on a besoin du champ absolument

      if (input.required) {
        input.addEventListener('input', this.validateInput.bind(this));
      }
    }
    // Sinon envoyer le form

    this.element.addEventListener('submit', this.onSubmit.bind(this));


  }

  /** Envoyer le formulaire */
  onSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      //envoi du form ajax
      this.showConfirmation();
    } else {
      console.log('Les champs ne sont pas tous remplie');
    }
  }

  /** Valider si les champs sont correct */
  validate() {
    let isValid = true;
    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];
      if (input.required && !this.validateInput(input)) {
        isValid = false;
      }
    }
    return isValid;
  }

  /** Ajouter ou enlever erreur */
  validateInput(input) {
    if (input.validity.valid) {
      this.removeError(input);
    } else {
      this.addError(input);
    }
    return input.validity.valid;
  }

  addError(input) {
    /** Si erreur ajout de la classe 'error' */
    const container =
      input.closest('[data-input-container]') || input.closest('.input');
    container.classList.add('error');
  }
  removeError(input) {
    /** Si pas erreur retrait de la classe 'error' */
    const container = input.closest('.input');
    container.classList.remove('error');
  }

  showConfirmation(input) {
    /*
     Afficher messages que le form est bien envoyé.
       ========================================================================== */
    this.element.classList.add('is-sent');
  }



}
