export default class Tabs {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    let tabs = document.querySelectorAll('.js-tab');
    this.texte = document.querySelectorAll('.tab__content');

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];

      tab.addEventListener('click', this.enleverClasse.bind(this));
    }
  }

  enleverClasse(event) {
    const tabClicker = event.target;

    const targetId = tabClicker.getAttribute('data-tab-id');

    const targetTexte = document.querySelector(
      `[data-tab-content-id="${targetId}"]`
    );

    /*  is active me sert a quand on clique sur une tab ouverte de la fermer */

    const isActive = targetTexte.classList.contains('is-active');
    const tabs = document.querySelectorAll('.js-tab');

    const textes = document.querySelectorAll('.tab__content');
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      const texte = textes[i];
      if (tab === tabClicker && !isActive) {
        tab.classList.add('is-active');
        texte.classList.add('is-active');
      } else {
        tab.classList.remove('is-active');
        texte.classList.remove('is-active');
      }
    }
  }
}
