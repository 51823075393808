export default class Search {
  constructor(element) {
    this.element = element;
    this.li = this.element.querySelector('.astm-search-menu');
    this.form = this.element.querySelector('.is-search-form');

    this.init();
  }

  /**
   * Initialise la composante Search.
   */
  init() {
    document.addEventListener('DOMContentLoaded', function () {
      var searchInput = document.querySelector('input[type="search"]');
      if (searchInput) {
        searchInput.setAttribute('placeholder', '');
      }
    });
    // Check if this.li is defined and contains an anchor element
    if (this.li && this.li.querySelector) {
      this.loupe = this.li.querySelector('a');
      if (this.loupe) {
        this.loupe.addEventListener('click', this.toggleForm.bind(this));
      } else {
        console.error('Anchor element not found within this.li');
      }
    } else {
      console.error(
        'Unable to find this.li or querySelector method not supported'
      );
    }
  }

  toggleForm() {
    if (this.form) {
      if (this.form.style.display === 'none') {
        this.form.style.display = 'block';
      } else {
        this.form.style.display = 'none';
      }
    } else {
      console.error('Form element not found');
    }
  }
}
