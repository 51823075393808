import Swiper from 'swiper/bundle';

/** Composante Swipper de Timtools */

export default class Carousel {
  /**
   * * La méthode  constructor set a définir les options de notre carousel.
   * @param {HTMLElement} element - Tout les element html qui contient data-component="Swiper"
   */

  constructor(element) {
    this.element = element;
    this.options = {
      slidesPerView: 1,
      spaceBetween: 24,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),

        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };
    this.init();
  }

  init() {
    this.setOptions();

    // Initier swipper avec les options choisie
    const swiper = new Swiper(this.element, this.options);
  }

  /**
   * * La méthode   setOptions set a modifié les options dépendant du carousel.
   * @param {HTMLElement} element - Tout les element html qui contient data-component=""
   */

  setOptions() {
    const variant = this.element.dataset.variant;
    // si autoplay dans le dataset du carousel
    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 1000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      };
    }
    // si le variant du carousel est split
    if (variant == 'split') {
      this.options.breakpoints = {
        768: {
          centeredSlides: true,
          slidesPerView: 1.5,
          spaceBetween: 100,
        },
        1200: {
          centeredSlides: true,
          slidesPerView: 1.5,
          spaceBetween: 100,
        },
      };
    }
    // si loop dans le dataset du carousel
    if ('loop' in this.element.dataset) {
      this.options = {
        loop: true,
      };
    }
    // si gap dans le dataset du carousel
    if ('gap' in this.element.dataset) {
      this.options.slidesPerView = 1;
      this.options.spaceBetween = 50;
      this.options.centeredSlides = true;
    }
    // si inverse dans le dataset du carousel
    if ('inverse' in this.element.dataset) {
      this.options.direction = 'vertical';
      this.options.slidesPerView = 2;
      this.options.pagination = {
        el: '.swiper-pagination',
        clickable: true,
      };
    }
  }

}