export default class Scrolly {
  constructor(element) {
    this.element = element;
    this.options = {
      rootmargin: '0px 0px 0px 0px',
    };
    this.init();
  }

  init() {
    const observer = new IntersectionObserver(
      this.watch.bind(this),
      this.options
    );
    const items = this.element.querySelectorAll('[data-scrolly]');
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      observer.observe(item);
    }
  }

  watch(entries, observer) {
    //entries,observer: n'a pas de liens avec la variable observer dans le init, c'est un parametre
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const target = entry.target; //corresspond a chacun des element html oui ou non

      if (entry.isIntersecting) {
        //console.log("oui");
        target.classList.add('is-active');

        if ('noRepeat' in this.element.dataset) {
          observer.unobserve(target); //pour arreter de faire rejouer les animations en remontant la page)
        }
      } else {
        //console.log("non");
        target.classList.remove('is-active');
      }
    }
  }
}
