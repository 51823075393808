export default class Header {
  constructor(element) {
    this.element = element;

    this.scrollLimit = 0.1;
    this.scrollPosition = 0;

    this.html = document.documentElement;
    this.init();
    this.initNavMobile();
    this.initBackToTopButton();
  }

  init() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  initNavMobile() {
    const toggles = this.element.querySelectorAll('.js-toggle');
    for (let i = 0; i < toggles.length; i++) {
      const toggle = toggles[i];
      toggle.addEventListener('click', this.onToggleNav.bind(this));
    }
    document.addEventListener('keydown', this.onEscapeKey.bind(this));
    document.addEventListener('click', this.handleLinkClick.bind(this));
  }

  initBackToTopButton() {
    const backToTopButton = document.getElementById('back-to-top-btn');

    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        backToTopButton.style.opacity = '1';
      } else {
        backToTopButton.style.opacity = '0';
      }
    });

    backToTopButton.addEventListener('click', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scrolling au top
    });

    backToTopButton.style.opacity = '0';
  }

  handleLinkClick(event) {
    const targetElement = event.target;

    const currentPathname = window.location.pathname;

    const linkPathname = targetElement.pathname;

    if (
      targetElement.tagName === 'A' &&
      targetElement.hash &&
      currentPathname === linkPathname &&
      this.html.classList.contains('nav-is-active')
    ) {
      this.html.classList.toggle('nav-is-active');
    }
  }

  onEscapeKey(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.html.classList.remove('nav-is-active');
    }
  }

  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }

  onScroll(event) {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;
    this.setHeaderState();
    this.setDirectionState();
  }

  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;

    if (this.scrollPosition > scrollHeight * this.scrollLimit) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }

  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.add('is-scrolling-up');
      this.html.classList.remove('is-scrolling-down');
    }
  }
}


var toggleButton = document.getElementById('toggle');
var overlay = document.getElementById('overlay');


//sous-menu 
document.addEventListener("DOMContentLoaded", function() {
  var menuItems = document.querySelectorAll('.menu-item-has-children > a');
  menuItems.forEach(function(item) {
      item.addEventListener('click', function(e) {
          e.preventDefault();
          var parent = this.parentNode;
          parent.classList.toggle('sub-menu-active');
      });
  });
});


var toggleButton = document.getElementById('toggle');
var overlay = document.getElementById('overlay');

toggleButton.addEventListener('click', function() {
  if (toggleButton.classList.contains('active')) {
    toggleButton.classList.remove('active');
  } else {
    toggleButton.classList.add('active');
  }
  
  if (overlay.classList.contains('open')) {
    overlay.classList.remove('open');
    document.documentElement.classList.remove('nav-active'); // Remove the class from html
  } else {
    overlay.classList.add('open');
    document.documentElement.classList.add('nav-active'); // Add the class to html
  }
});