import ComponentFactory from './ComponentFactory';
import Icons from './utils/Icons';

class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');

    new ComponentFactory();
    Icons.load();
  }
}


//////////

const tabButtons = document.querySelectorAll('.tab-btn')

tabButtons.forEach((tab) => {
  tab.addEventListener('click', () => tabClicked(tab))
})

function tabClicked(tab) {
  
  tabButtons.forEach(tab => {
    tab.classList.remove('active')
  })
  tab.classList.add('active')
  
  const contents = document.querySelectorAll('.content')
  
  contents.forEach((content) => {
    content.classList.remove('show')
  })
  
  const contentId = tab.getAttribute('content-id')
  const contentSelected = document.getElementById(contentId)
  
  contentSelected.classList.add('show')
  //console.log(contentId)
}



new Main();




//empecher que deux audio controls jouent en meme temps
    document.addEventListener('DOMContentLoaded', function () {
        var audioElements = document.querySelectorAll('.audio-control');
        var currentlyPlaying = null;

        audioElements.forEach(function (audio) {
            audio.addEventListener('play', function () {
                if (currentlyPlaying && currentlyPlaying !== audio) {
                    currentlyPlaying.pause();
                }
                currentlyPlaying = audio;
            });
        });
    });
