export default class VideoHero {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.video = document.querySelector('.videohero');

    if (this.video) {
      this.video.addEventListener(
        'canplaythrough',
        this.setHeroState.bind(this)
      );
    } else {
      console.log('Pas de vidéo');
    }
  }

  setHeroState() {
    this.video.classList.add('is-loaded');
  }
}
