/** Composante Accordeon pierre-carl */
export default class Accordeon {
  /**
   * Crée une instance de Accordeon.
   * @param {HTMLElement} element - Tous les éléments HTML qui contiennent data-component="Accordeon"
   */
  constructor(element) {
    this.element = element;
    this.init();
  }

  /**
   * Initialise la composante Accordeon.
   */
  init() {
    // Récupérez tous les boutons de l'accordéon à l'intérieur de l'élément
    const accordionButtons = this.element.querySelectorAll('.accordion-button');

    // Ajoutez des gestionnaires d'événements de clic aux boutons de l'accordéon
    accordionButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const content = button.nextElementSibling;
        const images = document.querySelectorAll('.sign');
        let image = button.closest('.accordion-button').querySelector('.sign');
        let OriginalSource = image.src.endsWith('assets/images/plus_sign.png');

        if (OriginalSource) {
          image.src = 'assets/images/minus_.png'; // Change to the original image source
        } else {
          image.src = 'assets/images/plus_sign.png'; // Change to the alternate image source
        }

        // Toggle the content visibility
        if (!content.classList.contains('display-true')) {
          content.classList.add('display-true');
        } else {
          content.classList.remove('display-true');
        }
      });
    });
  }
}
